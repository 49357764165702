<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Temps réel
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>

        <b-col
          xl="2"
          md="6"
          sm="6"
          class="mb-2 mb-xl-3"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                rounded
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="TrendingUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.countClient }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Clients
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="2"
          md="6"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.countClient49 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Clients 49
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="2"
          md="6"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.countClient85 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Clients 85
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="2"
          md="6"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.countClient79 }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Clients 79
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="2"
          md="6"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.countClientAutres }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Clients Autres
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import PouchDB from 'pouchdb'
// import PouchVue from 'pouch-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countClient: 0,
      countClient49: 0,
      countClient85: 0,
      countClient79: 0,
      countClientAutres: 0,
    }
  },
  created() {
    this.localDB = new PouchDB('clients')
    this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/clients')

    // Décompte des clients
    this.localDB.createIndex({
        index: { fields: ['type'] },
    })
    // Lecture des document sur l'index de recherhe type=clients)
    this.localDB.find({ selector: { type: 'clients' } }, this.setClients)

    this.localDB.sync(this.remoteDB, { live: true, retry: true })
    .on('change', (change) => {
      console.log("change")
      // Création d'un index
      this.localDB.createIndex({
        index: { fields: ['type'] },
      })
      // Lecture des document sur l'index de recherhe type=clients)
      this.localDB.find({ selector: { type: 'clients' } }, this.setClients)
    }).on('paused', (info) => {
      console.log("pause",)
    }).on('active', (info) => {
      console.log("active")      
    }).on('error', (err) => {
      console.log("err", err)
    });

  },
  methods: {
    setClients(err, doc) {
      if (err) {
        console.log('err', err)
      }
      this.countClient = 0
      this.countClient49 = 0
      this.countClient85 = 0
      this.countClient79 = 0
      this.countClientAutres = 0

      console.log('count', doc)
      this.countClient = doc.docs.length

      for (let i = 0; i < doc.docs.length; i++) {
        if (doc.docs[i].cp.toString().match(/49.*/)) {
          this.countClient49 = this.countClient49 + 1
        } else if (doc.docs[i].cp.toString().match(/85.*/)) {
          this.countClient85 = this.countClient85 + 1
        } else if (doc.docs[i].cp.toString().match(/79.*/)) {
          this.countClient79 = this.countClient79 + 1
        } else {
          this.countClientAutres = this.countClientAutres + 1
        }
      }

    },
  },
}
</script>
