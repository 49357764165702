<template>
  <section>
    <b-row class="match-height">
      <b-col
        xl="5"
        md="12"
      >
        <b-row class="match-height">
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="7"
        md="12"
      >
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
} from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import EcommerceOrderChart from './components/dashboard/EcommerceOrderChart.vue'
import EcommerceProfitChart from './components/dashboard/EcommerceProfitChart.vue'
import EcommerceEarningsChart from './components/dashboard/EcommerceEarningsChart.vue'
import EcommerceStatistics from './components/dashboard/EcommerceStatistics.vue'
import AnalyticsSupportTracker from './components/dashboard/AnalyticsSupportTracker.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceStatistics,
    AnalyticsSupportTracker,
  },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    kFormatter,
  },
  created()
  {
    // console.log("user", localStorage.getItem('user'))
  }
}

</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
